<template>
  <div class="tabbar">
    <router-view ref="subView"/>
    <van-tabbar route :active-color="$primary" inactive-color="#868c92" >
      <van-tabbar-item 
        v-for="(tab, index) in $AppData.config.appPageInfoList"
        :key="tab.tab"
        :to="$AppData.pageMapping[tab.code]"
        :replace="!tab.type"
        >
        <template #icon="props">
          <p v-if="props.active" class="tabbar-title">{{ tab.show }}</p>
          <img v-else :src="tab.iconUrl" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { store } from '@/store';
  
export default {
  data() {
    return {};
  },
  
  mounted() {
    // store.fetchQuickMenu();
    // store.fetchTransactionUrl();
  },

  methods: {},
};
</script>

<style lang="less">
  .tabbar {
    .van-tabbar {
      justify-content: space-around;
    }
    .van-tabbar-item {
      flex: 0;
    }
    .van-tabbar-item__icon {
      margin-bottom: 0;
    }
    .tabbar-title {
      padding: 10px;
      font-size: 13px;
      font-weight: 600;
      color: #fff;
      background-color: var(--van-primary-color);
      border-radius: 4px;
      white-space: nowrap;
    }
  }
</style>
